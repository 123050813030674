export const firebaseConfig = {
  // Project: ws-staging-0
  // App: Dashboard (staging)
  // Hosting: ws-staging-0.web.app
  apiKey: 'AIzaSyBc9679rbsvxRUmPm2Q63ZN4mLwzlqerUw',
  authDomain: 'ws-staging-0.firebaseapp.com',
  projectId: 'ws-staging-0',
  storageBucket: 'ws-staging-0.appspot.com',
  messagingSenderId: '398838124809',
  appId: '1:398838124809:web:c35c4946d678f2f41043d8',
  //measurementId: 'G-PM7934FF6K', // No measurementId supplied from firestore - ND
};

export const wsConfig = {
  envName: 'staging',
  rollbar: {
    accessToken: '87e02a03ad474af5bba15e3a57a833f7',
    environment: 'staging',
  },
  hotjar: {
    siteId: 3759624,
    version: 6,
  },
};
